<template>
  <div class="window-wrapper">
    <Landscape :class="state.anime ? 'anime_landscape' : ''" :vagon="state.vagon"/>


    <div
      class="window"
      :style="`background-image: url(./img/staterooms/${state.sceen}_back.png)`"
    >


      <StartWindow @choose="choose()" v-show="state.window === 'start'"/>

      <div class="finalWindow" v-show="state.window === 'final'">
        <div class="f_heading">{{state.final.heading}}</div>
        <div class="f_text">{{state.final.text}}</div>
        <div class="f_product">Скучаешь по&nbsp;железнодорожной романтике?<br />Организуй себе путешествие в&nbsp;Карелию, на&nbsp;Волгу или столичный мегаполис в&nbsp;комфортных поездах РЖД!</div>
        <div class="f_line"></div>

      </div>

      <Bubble :class="state.phrase" :phrase="data.sceens[state.sceen][state.phrase]"/>
      <Bubble :class="`mistake ${state.mistake ? 'show' : ''}`" :phrase="data.mistake"/>

      <div class="characters">

        <div
          :style="`background-image: url(./img/${state.vagon}/${characters[index].img}.PNG);
          z-index: ${characters[index].zindex}`"
          v-for="(c, index) in characters"
          :key="index"
          class="char"
        >

        </div>

      </div>

      <div :class="`check_window ${state.window == 'check' ? 'check_show' : ''}`">
        <div class="check_box">
          <div class="check red"></div>
        </div>
      </div>

      <div
      :class="`check_window ${state.window == 'tip' ? 'check_show' : ''}`"
      >
        <div  v-html="data.sceens[state.sceen].tip" class="tip-text"></div>
        <div class="tip-button" @click="closeTip()">
        </div>

      </div>

    </div>

    <div class="doorContainer" :style="`right: ${state.window == 'door' || state.window == 'door_finish' ? '10px' : '100%'}`">
      <div class="doorsWrapper" :style="`right: calc(100% * ${state.sceenNumber})`">
        <Door
          v-for="(door, index) in doorNames"
          :key="index"
          :name="data.sceens[doorNames[index]].name"
          :state="state"
          :index="index"
        />
      </div>
    </div>


    <div class="window_border"></div>

  </div>
</template>

<script>
import StartWindow from './StartWindow.vue'
import Door from './Door.vue'
import Bubble from './Bubble.vue'
import Landscape from './Landscape.vue'

export default {
  name: 'Window',

  components:{
    StartWindow,
    Door,
    Bubble,
    Landscape
  },

  computed:{
    doorNames(){
      return Object.keys(this.data.sceens)
    }
  },

  props: ['characters', 'data', 'state'],

  methods:{
    choose(){
      this.$emit('choose')
    },
    closeTip(){
      this.$emit('closeTip')
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped src="../assets/styles/window.scss"></style>
