import Vue from 'vue'
import App from './App.vue'
import VueYandexMetrika from 'vue-yandex-metrika';

Vue.use(VueYandexMetrika, {
    id: 83756080,
    router: false,
    env: 'production'
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
