<template>
  <div id="bubble" class="bubble " v-html="phrase">

  </div>
</template>

<script>

export default {
  name: 'Bubble',
  props: ['phrase'],

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
  .bubble{
    width: 340px;
    height: 95.8px;

    font-size: 14px;
    padding: 5px 15px 15px;
    text-transform: uppercase;
    line-height: 130%;

    position: absolute;
    left: 40px;
    z-index: 5;
    bottom: -83px;
    margin-bottom: 0;

    background-size: cover;
    background-position: contain;
    background-repeat: no-repeat;
    background-image: url(../../public/img/bubble.svg);

    display: flex;
    align-items: center;
    justify-content: center;

    transition: margin-bottom 1s cubic-bezier(.4,1.98,.35,1.51);
  }
  
  .startPhrase, .finalPhrase{
    margin-bottom: 145px;
  }

  .show{
    margin-bottom: 145px;
  }

  .plate{
    font-family: "Gora-Free";
    font-size: 24px;
    line-height: 120%;
    width: 222px;
    height: 126px;
    background: linear-gradient(180.09deg, #FFD4AC -15.58%, #FFEEB8 99.92%);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 2px;

    position: absolute;
    top: 20%;
    margin-left: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 450px) {
    .bubble{
      transform: scale(1.5);
      left: 140px;
      bottom: -100px;
    }

    .startPhrase, .finalPhrase{
      margin-bottom: 195px;
    }
  }
</style>
