<template>
  <div class="strip">
    <div v-html="data.name" v-show="state.window != 'swipe'" class="strip_name"></div>
    <div class="nextStateroom" @click="swipeNext" v-show="state.window === 'door_finish'"></div>

    <div class="choose_text" v-show="state.window === 'start'"> Выбери поезд </div>
    <Swiper :state="state" @chooseVagon="chooseVagon" @preChooseVagon="preChooseVagon"  v-show="state.window === 'start'"/>
    <SwiperFlat @character="characterC"  @mistake="mistake" :state="state" :data="data" v-show="state.window === 'game' || state.window === 'check'"/>

    <div v-show="state.window === 'final'" class="restart" @click="restart"></div>

    <div class="choose_text share_text" v-show="state.window === 'final'">поделиться результатом</div>

    <div class="socials" v-show="state.window === 'final'">
      <img alt="tw" :src="`/img/tw${state.vagon == 'anime' ? '_blue' : ''}.svg`" class="social tw" @click="share('tw')">
      <img alt="vk" :src="`/img/vk${state.vagon == 'anime' ? '_blue' : ''}.svg`"  class="social vk" @click="share('vk')">
      <img alt="fb" :src="`/img/fb${state.vagon == 'anime' ? '_blue' : ''}.svg`"  class="social fb" @click="share('fb')">
      <img alt="ok" :src="`/img/ok${state.vagon == 'anime' ? '_blue' : ''}.svg`"  class="social ok" @click="share('ok')">
    </div>

  </div>
</template>

<script>
import Share from '../share'
import { shares } from '../assets/data.json'
import Swiper from './Swiper.vue'
import SwiperFlat from './SwiperFlat.vue'

export default {
  name: 'Strip',
  props: ['data', 'state'],
  components:{
    Swiper,
    SwiperFlat
  },

  data(){
    return{
      shares,
    }
  },

  methods:{

    share(soc) {
      let share = new Share({
       url: `https://vpoezde.special.ink/results/${this.state.vagon}_${this.state.final.index}.html`,
       title: this.shares[this.state.final.index].heading,
       image: `https://vpoezde.special.ink/img/og/${this.state.vagon}_${this.state.final.index}.png`,
       description: this.shares[this.state.final.index].text,
       id: 83756080,
       metrics: this.$metrika,
       library: 'vue',
      });
      share.open(soc);
    },

    restart(){
      this.$emit('restart')
    },

    swipeNext(){
      this.$emit('swipeNext')
    },

    preChooseVagon(i){
      this.$emit('preChooseVagon', i)
    },

    chooseVagon(i){
      this.$emit('chooseVagon', i)
    },

    characterC(i){
      this.$emit('character', i)
    },

    mistake(){
      this.$emit('mistake')
    }

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped src="../assets/styles/strip.scss"></style>
