<template>
  <div :class="`door ${CV}`">
    <div class="plate" v-html="name"></div>
  </div>
</template>

<script>

export default {
  name: 'Landscape',
  props: ['name', 'state', 'index'],

  computed:{
    CV(){
      if (
        (this.state.vagon == 'anime' ||
        this.state.vagon == 'series') &&
        this.index == 3
      ) {
        return 'cv'
      } return ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss" >
@import '../assets/styles/variables.scss';

  .door{
    height: 100%;
    width: 25%;

    background-size: 100% auto;
    background-position: center;
    background-repeat: repeat-x;
    background-image: url(../../public/img/door.png);

    display: flex;
    align-items: center;
    justify-content: center;

  }

  .cv{
    background-size: 120% auto;
    background-position:  45% center;
    background-image: url(../../public/img/door-cv.png);
  }

  .plate{
    font-family: "Gora-Free";
    font-size: 18px;
    line-height: 120%;
    line-height: 120%;
    width: 222px;
    height: 126px;
    background: linear-gradient(180.09deg, #FFD4AC -15.58%, #FFEEB8 99.92%);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    @include flex;
    flex-direction: column;

    position: absolute;
    top: 20%;
    margin-left: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
