<template>
  <div id="app" :class="this.state.vagon">

    <div class="preloads">
      <div :class="`preload preload-${index}`"
      v-for="(p, index) in 17"
      :key="index"
      >

      </div>
    </div>

    <div class="windowContainer" :preChooseVagon="preChooseVagon">
      <div class="stats" v-show="state.window != 'start'">

        <div class="hearts">
          <div v-for="(h, index) in state.hearts" :key="'h' + index" class="heart"></div>
          <div v-for="(h, index) in emptyHearts" :key="'h-e' + index" class="heart heart_empty"></div>
        </div>

        <div class="chars_container">
          <div v-for="(h, index) in characters.length" :key="'c' + index" class="char_stat"></div>
          <div v-for="(h, index) in emptyChars" :key="'c-e' + index" class="char_stat char_empty"></div>
        </div>

      </div>

      <Window @closeTip="closeTip" @choose="chooseVagon(state.vagon)" :characters="characters" :state="state" :data="testData[state.vagon]"/>
    </div>

    <Strip @restart="restart" @swipeNext="swipe" @preChooseVagon="preChooseVagon" @chooseVagon="chooseVagon" @character="addCharacter" @mistake="removeHeart" :state="state" :data="testData[state.vagon]" :class="stripClass"/>

    <div class="final" v-show="state.window == 'final'">
      <div class="f_img" :style="`background-image: url(./img/endings/${state.vagon[0]}-${state.final.index}-0${mobile}.png)`"></div>
      <div class="f_img f_img_2" :style="`background-image: url(./img/endings/${state.vagon[0]}-${state.final.index}-1${mobile}.png)`"></div>
    </div>
  </div>
</template>

<script>
import Window from './components/Window.vue'
import Strip from './components/Strip.vue'
import { testData } from './assets/data.json'
import { finals } from './assets/data.json'

export default {
  name: 'App',

  data(){
    return {
      testData,
      finals,
      characters: [],
      state: {
        finishedTrains: '',
        anime: false,
        sceenNumber: 0,
        vagon: 'movie',
        window: 'start',
        sceen: 'tarantino',
        hearts: 5,
        mistake: false,
        phrase: '',
        final: ''
      }
    }
  },

  mounted(){

    this.state.finishedTrains = window.localStorage.getItem('trains')

    if (this.state.finishedTrains) {
      if (this.state.finishedTrains.includes('movie') && this.state.finishedTrains.includes('anime')) {

        this.state.vagon = 'series'
        this.state.sceen = 'stranger'


      } else if (this.state.finishedTrains.includes('movie') ) {

        this.state.vagon = 'anime'
        this.state.sceen = 'fathers'

      }
    }


    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    this.state.final = this.finals[0]
  },

  computed:{

    mobile(){
      if (window.innerWidth <= 450) {
        return '-m'
      } return ''
    },

    emptyHearts(){
      const a = 5 - this.state.hearts
      return a
    },

    emptyChars(){
      const a = this.testData[this.state.vagon].sceens[this.state.sceen].characters.length - this.characters.length
      return a
    },

    objectKeys(){
      return Object.keys(this.testData[this.state.vagon].sceens)
    },

    stripClass(){
      switch (this.state.window) {
        case 'door':

          return 'name';

        case 'start':

          return 'swiper';

        case 'game':

          return 'game';

        case 'tip':

          return 'tip';

        default:
          return 'swiper'

      }
    }
  },

  components: {
    Window,
    Strip
  },

  methods:{

    sendMetrika(goal){
      if (this.$metrika) {
          this.$metrika.reachGoal(goal);
        }
    },

    preChooseVagon(i){
      this.state.vagon = i
      this.state.sceen = this.objectKeys[0]
    },

    chooseVagon(i){

      let ls = window.localStorage
      let lsString = window.localStorage.getItem('trains')

      if (lsString) {
        if (!lsString.includes(this.state.vagon)) {
          ls.setItem('trains', lsString + ` ` + this.state.vagon);
        }
      }

      this.state.window = 'door'
      this.sendMetrika(`${i}_started`)

      this.chageStateroom('start')

    },

    removeHeart(){
      if (this.state.hearts - 1 == 0) {
        this.final();
        return
      }
        this.state.hearts -= 1;
        this.state.mistake = true;

        setTimeout(() => {
          this.state.mistake = false
        }, 3000);
    },

    addCharacter(i){

      if (!this.characters.includes(i)) {
        this.characters.push(i)
      }

      if (this.characters.length === this.testData[this.state.vagon].sceens[this.state.sceen].characters.length) {
          this.chageStateroom('finish')
      }

    },

    closeTip(){
      this.chageStateroom('game')
    },

    chageStateroom(a){

      switch (a) {

        case 'finish':

          setTimeout( () => {
            this.state.window = 'check';
          }, 1000);

          setTimeout(() => {
            this.chageStateroom('door')
          }, 3000);

          break;

        case 'door':

          setTimeout(() => {
            this.characters = [];
            this.state.phrase = 'finalPhrase';
          }, 1000);

          if (this.state.sceenNumber == 3) {
            this.state.window = 'door';

            setTimeout(() => {
              this.state.phrase = '';
            }, 3500);

            setTimeout(() => {
              this.final()
            }, 3000);
          } else {
            this.state.window = 'door_finish';
            this.sendMetrika(this.state.sceen)
          }

          break;

        case 'start':

          this.state.sceen = this.objectKeys[this.state.sceenNumber];
          this.state.phrase = 'startPhrase';

          setTimeout(() => {
            this.chageStateroom('tip')

          }, 3500);

          break;

        case 'game':

          this.state.window = 'game';

          if (!this.state.anime) {
            setTimeout( () => {
              this.state.anime = true

            }, 1000);
          }
          break;


        case 'tip':

          this.state.phrase = '';

          this.state.window = 'tip';

          break;

      }

    },

    restart(){
      location.reload()
      this.sendMetrika(`restart`)
    },

    final(){
      this.state.window = 'final'
      this.sendMetrika(`${this.state.vagon}_end`)

      switch (this.state.hearts) {
        case 5:
        case 4:

          this.sendMetrika(`end_0`)
          this.state.final = this.finals[0]
          break;

        case 3:
        case 2:

          this.sendMetrika(`end_1`)
          this.state.final = this.finals[1]
          break;

        case 1:
        case 0:

          this.sendMetrika(`end_2`)
          this.state.final = this.finals[2]
          break;

      }
    },

    swipe(){
      if (this.state.sceenNumber + 1 > 3) {
        this.final()
        return
      }

      this.state.window = 'door';

      this.state.phrase = '';

      setTimeout(() => {
        this.state.sceenNumber += 1;
      }, 2000);

      setTimeout(() => {
        this.chageStateroom('start')
      }, 3000);
    }

  }
}
</script>

<style lang="scss" src="./assets/styles/main.scss"></style>
