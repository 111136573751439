<template>
  <div class="startWindow">
    <h1>Нереальные попутчики</h1>
    <p>Путешествие в&nbsp;поезде&nbsp;&mdash; это не&nbsp;только чай в&nbsp;подстаканниках и&nbsp;ёлки за&nbsp;окном. Это ещё и&nbsp;знакомства с&nbsp;интересными людьми. Ну, или не&nbsp;совсем людьми!<br /><br />Выбери один из&nbsp;трёх поездов, загляни во&nbsp;все купе и&nbsp;угадай по&nbsp;вещам и&nbsp;обстановке, кто твои попутчики. Тебя ждёт удивительная компания!</p>
    <div @click="choose()" class="choose">Поехали</div>
    <div class="white_line"></div>
  </div>
</template>

<script>
export default {
  name: 'StartWindow',
  methods: {
    choose(){
      this.$emit('choose')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped src="../assets/styles/startWindow.scss"></style>
