<template>
  <div class="landscape-wrapper">
    <div id="landscape_0" :style="`background-image: url(./img/${vagon}/landscape.jpg)`" class="landscape landscape_0"></div>
    <!-- <div class="landscape landscape_1"></div> -->
  </div>
</template>

<script>

export default {
  name: 'Landscape',
  props: ['vagon']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped src="../assets/styles/landscape.scss"></style>
