<template>
  <carousel-3d @keyup.39="handleRightArrow" @before-slide-change="onAfterSlideChange"  class="carousel-3d" :width="width" :height="height" :onMainSlideClick="chooseVagon" inverseScaling="100" :space="space" perspective="0" :controlsVisible="true" display="3">
    <slide class="slide" :index="indexes[0].index">
      <div class="train train-0" >Кинопоезд «Карелия» Петрозаводск - Москва</div>
    </slide>
    <slide class="slide" :index="indexes[1].index">
      <div class="train train-1">анимационный поезд<br>«Красная стрела»</div>
    </slide>
    <slide class="slide" :index="indexes[2].index">
      <div class="train train-2">Сериальный поезд «самара — москва»</div>
    </slide>
  </carousel-3d>
</template>

<script>
  import { Carousel3d, Slide } from 'vue-carousel-3d'

  export default {

    components: {
      Carousel3d,
      Slide
    },

    props: ['state'],

    computed:{
      indexes(){
        const trains = this.state.finishedTrains

        let arr = [
          {
            index: 0,
            vagon: 'movie',
          },
          {
            index: 1,
            vagon: 'anime',
          },
          {
            index: 2,
            vagon: 'series',
          },
        ]

        if (!trains) {
          return arr
        }

        if (trains.includes('movie') && trains.includes('anime')) {

          arr = [
            {
              index: 1,
              vagon: 'movie',
            },
            {
              index: 2,
              vagon: 'anime',
            },
            {
              index: 0,
              vagon: 'series',
            },
          ]


        } else if (trains.includes('movie') ) {

          arr = [
            {
              index: 1,
              vagon: 'movie',
            },
            {
              index: 0,
              vagon: 'anime',
            },
            {
              index: 2,
              vagon: 'series',
            },
          ]

        }

        return arr
      },

      width(){
        if (window.innerWidth <= 450) {
          return 196 * 0.77
        }

        return 196
      },
      height(){
        if (window.innerWidth <= 450) {
          return 133 * 0.77
        }

        return 133
      },
      space(){
        if (window.innerWidth <= 450) {
          return 296 * 0.6
        }

        return 296
      },
    },

    data(){
      return {
        currentSlide: 0,

      }
    },

    methods:{
      onAfterSlideChange(e){
        this.currentSlide = e;

        for (let i = 0; i < this.indexes.length; i += 1) {
          if (this.indexes[i].index == e) {
            this.$emit('preChooseVagon', this.indexes[i].vagon);
          }
        }
      },

      chooseVagon(e){

        switch (e.index) {
          case 0:

          this.$emit('chooseVagon', 'movie');
            break;

          case 1:
          this.$emit('chooseVagon', 'anime');
            break;

          case 2:
          this.$emit('chooseVagon', 'series');
            break;

        }
      }
    }

  };
</script>

<style lang="scss" src="../assets/styles/swiper.scss">
</style>
