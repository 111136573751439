<template>
  <div @click="disableFinger()" @scroll="disableFinger()" :class="`characters_wrapper ${finger ? 'finger_active' : ''} ${state.vagon == 'movie' || state.vagon == 'series' ? 'red' : 'white'}`">
    <div class="characters" >

      <div
        class="character"
        v-for="(character, index) in arr"
        :key="index"
        @click="guess(character)"
        v-show="!guessed.includes(character)"
        :style="`background-image: url(./img/${state.vagon}_strip/${character}.png)`"
      >
      </div>

    </div>

    <div class="finger" @click="disableFinger()"></div>
  </div>
</template>

<script>
  export default {
    props: ['data', 'state'],

    data(){
      return {
        guessed: [],
        finger: true
      }
    },

    computed:{
      length(){

        if (this.state.vagon == 'anime' || this.state.vagon == 'series') {
          return 16
        } return 18

      },

      arr(){
        let arr = [];

         for (let i = 0; i < this.length; i += 1) {
           arr.push(i)
         }

         arr = this.shuffleArray(arr)

         return arr
      }
    },

    methods:{



     shuffleArray(a) {
          var currentIndex = a.length,  randomIndex;

          // While there remain elements to shuffle...
          while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [a[currentIndex], a[randomIndex]] = [
              a[randomIndex], a[currentIndex]];
          }

        return a
      },

      disableFinger(){
        this.finger = false
      },

      guess(index){

        if (this.guessed.includes(index)) {
          return
        }

        const chars = this.data.sceens[this.state.sceen].characters

        for (let i = 0; i < chars.length; i += 1) {

          if (index == chars[i].img) {
            this.$emit('character', chars[i]);
            this.guessed.push(index);

            return
          }

        }

        this.$emit('mistake');

      }

    }
  }
</script>

<style lang="scss">
  @import '../assets/styles/variables.scss';

  .finger{
    position: absolute;
    height: 100px;
    width: 100%;

    @include back;
    background-image: url(../../public/img/finger.png);
    display: none;
    margin-top: 20px;
  }

  .finger_active{

    .finger{
      display: block;
      animation: 1s linear infinite alternate finger;
    }

    .characters{
      animation: 1s linear .2s  infinite alternate finger;
    }
  }

  @keyframes finger {
    0%{
      margin-left: -100px;
    }
    100%{
      margin-left: 0px;
    }
  }

  .characters_wrapper{
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin-top: 30px;
    overflow-x: scroll;
    overflow-y: hidden;
    @include flex;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      width: 1em;
      height: 11px;
    }

    &::-webkit-scrollbar-track {
      @include back;
      background-size: 100% auto;
      background-color: rgba(255, 255, 255, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: #B32F2F;
      height: 11px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5), inset 0px 1px 1px rgba(255, 255, 255, 0.5);
      border-radius: 70px;
    }
  }

  .red{

    &::-webkit-scrollbar-track {
      background-image: url('../../public/img/red_line.svg');
    }

    &::-webkit-scrollbar-thumb {
      background: #B32F2F;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5), inset 0px 1px 1px rgba(255, 255, 255, 0.5);
      border-radius: 70px;
    }
  }

  .white{

    &::-webkit-scrollbar-track {
      background-image: url('../../public/img/white_line.svg');
    }

    &::-webkit-scrollbar-thumb {
      background: #E6E6E6;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5), inset 0px 1px 1px rgba(255, 255, 255, 0.5);
      border-radius: 70px;
    }
  }

  .characters{
    height: 100%;
    overflow-x: visible;
    width: auto;
    display: inline-flex;
  }

  .character{
    height: 90%;
    width: 142px;
    margin: 0 22px;

    @include back;
    background-size: cover;
    cursor: pointer;

    border: 3.1791px solid #FFE7E7;
    border-radius: 5.29851px;
    background-color: #D56969;

    &:hover, &:active{
      border: 3.1791px solid #FFE7E7;
    }
  }

  @media (max-height: 700px){
    .character{
      margin: 0 15px;

    }

    .characters_wrapper{
      margin-top: 25px;

      &::-webkit-scrollbar {
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background: #B32F2F;
        height: 7px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5), inset 0px 1px 1px rgba(255, 255, 255, 0.5);
        border-radius: 70px;
      }
    }
  }

  @media (max-width: 450px){
    .character{
      height: 90%;
      width: 110px;
      margin: 0 10px;
    }

    .characters_wrapper{
      margin-top: 23px;
      height: 100%;
    }

  }


</style>
